import {
  BrowserRouter,
  Routes,
  Route, Navigate,
} from "react-router-dom";

import {ApplicationProvider} from "./services/AppContext.jsx";
import PrivateRoutes from "./services/PrivateRoute.jsx";

import Authorize from "./pages/oauth/Authorize.jsx";
import ManageSite from "./pages/ManageSite.jsx";
import Resources from "./pages/Resources.jsx";
import Research from "./pages/Research.jsx";
import NotFound from "./pages/NotFound.jsx";
import Contact from "./pages/Contact.jsx";
import About from "./pages/About.jsx";
import Terms from "./pages/Terms.jsx";
import Home from "./pages/Home.jsx";

function App() {
  return (
    <ApplicationProvider>
      <BrowserRouter>
        <Routes>
          {/* Public Routes */}
          <Route path="oauth/authorize" element={<Authorize/>}/>
          <Route path="/" element={<Home/>}/>
          <Route path="/terms" element={<Terms/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/research" element={<Research/>}/>
          <Route path="/about" element={<About/>}/>

          {/* Private Routes - Only allow authenticated users */}
          <Route element={<PrivateRoutes pageTitle={"Clinical Resources"}/>}>
            <Route path="/resources" element={<Resources/>}/>
          </Route>
          
          {/* Private Routes - Only allow admin users */}
          <Route path="/admin"  element={<PrivateRoutes groups={["admin-users"]}/>}>
            <Route path="/admin/home" element={<ManageSite/>}/>
          </Route>
          
          {/* redirect old routes and handles 404 */}
          <Route path="study" element={<Navigate to='/'/>}/>
          <Route path='*' element={<NotFound/>}/>
        </Routes>
      </BrowserRouter>
    </ApplicationProvider>
  );
}

export default App;
