import {useAuth} from "../services/auth/CognitoAuthContext";
import {NavLink} from "react-router-dom";
import React, {useState} from 'react'
import SvgIcon from "./SvgIcon.jsx";
import clsx from "clsx";

import {useClickOutside} from "../services/AppContext.jsx";
import UserPermission from "./UserPermission.jsx";
import {ThemeSwitcher} from "./ThemeButton.jsx";

export function ProfileMenu({classNames}) {
  const menuRef = useClickOutside(() => setIsOpen(false));
  const {authenticated, userInfo, login, logout} = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const closeMenu = () => {
    setIsOpen(false);
  }
  const handleButtonClick = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div ref={menuRef} className="relative">
      <div className={clsx(classNames, "flex relative")}>
        <button onClick={handleButtonClick} type="button" aria-expanded={isOpen} aria-haspopup="true">
          <span className="sr-only">Open user menu</span>
          <SvgIcon classNames={clsx("h-8 w-8", authenticated ? "fill-proof-green" : "fill-gray-300")} type="profile"/>
        </button>
      </div>
      {(isOpen && authenticated) && (<div onClick={closeMenu}
        className="profile-menu"
        role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
        <div className="border-1 border-b px-3 pb-2 select-none mb-2">
          <div className="text-sm text-gray-900" aria-hidden="true">{userInfo.name}</div>
          <div className="text-xs text-gray-500" aria-hidden="true">{userInfo.email}</div>
        </div>
        <ThemeSwitcher />
        <UserPermission groups={["admin-users"]}>
          <NavLink to={"/admin/home"} className="profile-item">Manage Site</NavLink>
        </UserPermission>
        <button onClick={logout} className="profile-item" role="menuitem">Sign out</button>
      </div>)}
      {(isOpen && !authenticated) && (<div onClick={closeMenu} className="profile-menu"
         role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
        <ThemeSwitcher />
         <button onClick={login} className="profile-item" role="menuitem">Sign In</button>
       </div>)}
    </div>)
}
