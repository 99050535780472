import React, {useEffect} from 'react';
import PageNavigation from "./PageNavigation.jsx";
import PageFooter from "./PageFooter.jsx";
import Container from "./Container.jsx";
import PageTitle from "./PageTitle.jsx";
import {useLocation} from "react-router-dom";

const topNavigation = [
  {title: "Home", path: "/", colour: "border-proof-green"},
  {title: "About", path: "/about", colour: "border-proof-orange"},
  {title: "Contact", path: "/contact", colour: "border-proof-blue"},
  {title: "Research", path: "/research", colour: "border-proof-yellow"},
  {title: "Resources", path: "/resources", colour: "border-proof-purple"},
];

const PageLayout = ({children, title, isFullWidth}) => {
  const location = useLocation();

  // updates header canonical link seo
  useEffect(() => {
    let canonicalTag = document.querySelector("link[rel='canonical']");
    if(!canonicalTag) {
      canonicalTag = document.createElement("link");
      canonicalTag.rel = "canonical";
      document.head.appendChild(canonicalTag);
    }
    canonicalTag.href = `https://proof-online.net${location.pathname}`;
  }, [location]);


  return (
    <div className="flex flex-col h-full">
      <PageTitle title={title || ""}/>
      {/* Hero section */}
      <PageNavigation links={topNavigation} useColours={true}/>
      <main className="flex-grow mb-8 sm:mb-16 mb:mb-32">
        {isFullWidth ? children : <Container>{children}</Container>}
      </main>
      <PageFooter/>
    </div>
  );
}
export default PageLayout;
