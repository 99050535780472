import React, {useState, useEffect} from "react";

import PageLayout from "../components/PageLayout.jsx";
import {useApi} from "../services/ApiService";
import ExportCsvComponent from "../components/ExportCsv.jsx";

const sortObjectsByKey = (key) => ((a, b) => {
  a = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
  b = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key]
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
});

export default function ManageSite() {
  const api = useApi();

  const [paginationToken, setPaginationToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  const fetchUsers = async (signal = {}) => {
    setLoading(true);
    try {
      const params = paginationToken ? {token: paginationToken, limit: 50} : {limit: 50};
      const response = await api.usersGet(params);
      if (signal.abort === true) return;
      if (response.success) {
        setError(null);
        const {data} = response;
        setPaginationToken(data.token || null);
        setUsers((prevUsers) => {
          const users = signal.reset ? data.users : [...prevUsers, ...data.users];
          return users.sort(sortObjectsByKey('name'));
        });
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const signal = {abort: false, reset: true};
    fetchUsers(signal);
    return () => signal.abort = true;
  }, []);

  const count = {
    users: users.length,
    terms: users.filter(u => u?.custom?.terms === "true").length,
    confirmed: users.filter(u => u?.userStatus === "CONFIRMED").length
  }

  return (
    <PageLayout title="Admin Portal">
      <section aria-labelledby="section-heading rounded-lg">
        <div className="relative rounded-lg bg-gray-300 dark:bg-gray-600 bg-opacity-50 rounded-lg p-4">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h2>Users 
                {loading && (<span className="ml-2 relative inline-flex h-3 w-3">
                                          <span
                                            className="absolute inline-flex h-full w-full rounded-full opacity-75 animate-ping bg-proof-blue"></span>
                                          <span
                                            className="relative inline-flex rounded-full h-3 w-3 bg-proof-blue"></span>
                                        </span>)}
                {paginationToken && (
                  <a href="#" onClick={fetchUsers} className="ml-2 text-sm text-proof-purple hover:underline">load more</a>)}
              </h2>
              <p className="mt-2">A list of all the site users including their name, email, role and status.</p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex flex-col justify-center items-end">
              <ExportCsvComponent data={users} />
            </div>
          </div>
          <div className="mt-2 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle lg:px-8">
                <table className="min-w-full divide-y divide-gray-600 dark:divide-gray-300">
                  <colgroup>
                    <col className="sm:w-3/12"/>
                    <col className="sm:w-4/12"/>
                    <col className="sm:w-2/12"/>
                    <col className="sm:w-1/12"/>
                  </colgroup>
                  <thead className="bg-gray-400 dark:bg-gray-800">
                  <tr>
                    <th scope="col" className="pl-4 pr-3 py-3.5 text-left text-sm font-semibold sm:pl-4">Name</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">Email</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">Terms ({count.terms})</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">Account ({count.confirmed}/{count.users})</th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-4">
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-400 dark:divide-gray-200">
                  {loading && users.length === 0 && (<tr>
                    <td colSpan={5}
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm font-medium sm:pl-4">Loading...
                      please wait
                    </td>
                  </tr>)}
                  {users.map((user) => {
                    return (<tr key={user.sub}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-4">{user.name}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">{user.email} ({user.username})</td>
                      <td
                        className="whitespace-nowrap px-3 py-4 text-sm">{user?.custom?.terms === "true" ? "Yes" : "No"}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">{user.userStatus}</td>
                      <td
                        className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-4 flex justify-end gap-2">
                        {/*{user?.custom?.terms === "true" && <SurveyView survey={termsAndConditions} />}*/}
                      </td>
                    </tr>);
                  })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  )
}