import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "proof": {
        "title": "PROOF",
        "authors": "L. M. Porter, C. Sheekey, E. Brady, B. E. Kearney",
        "welcome": "Welcome to PROOF",
        "study": "Thank you for your participation in our study",
        "comingSoon": "Coming Soon",
        "enquiryEmail": "info@proof-online.net",
        "termsTitle": "Terms and Conditions",
        "surveyTitle": "Survey",
        "loginButton": "Login",
        "logoutButton": "Logout",
        "resourcesButton": "View Resources",
        "description": "_P_rofessional Reasoning and _R_eflection on _O_bservation: An _O_rganisational _F_ramework",
        "intro": {
          "0": "PROOF is an emerging clinical reasoning and reflection tool for organising clinical observations across developmental domains to support the design of individualised play-based interventions.",
          "1": "PROOF provides a structure for clinical reasoning, guiding therapists to focus on connected body-based interventions that support a child’s sense of agency through meaningful occupation and embodied experiences.",
          "2": "PROOF supports a clinician’s reflection-on-action, leading to the more advanced clinical process of reflection-in-action.",
        },
      },
      "about": {
        "title": "What is PROOF?",
        "description": {
          "0": "PROOF is a structured observational framework that supports advanced professional reasoning and reflection for pediatric occupational therapists using a sensory-motor, play-based approach. It is designed to guide therapists in organising qualitative data across the domains of praxis, postural, and social-emotional development, while considering each child's individual sensory processing differences and encouraging self-reflection on part of the therapist. Through structured observations and guided reasoning, therapists use PROOF to design individualised, occupation-based interventions centered around child-led play. An overarching goal of PROOF is to emphasize the therapeutic benefits of embodiment, both for the client and for the therapist in advancing their professional reasoning.",
          "1": "PROOF is a free resource."
        },
        "authors": "About the Authors"
      },
      "authors": {
        "lisa": {
          "title": "Lisa Porter",
          "bio": {
            "0": "Lisa Porter, PhD, OTD, OTR/L is an occupational therapist with almost 30 years of clinical experience. She received her master's in Occupational Therapy from Rockhurst University and her OTD and PhD from Rocky Mountain University of Health Professions. Dr. Porter has advanced training in sensory integration (SIPT), Interpersonal Neurobiology, SOS Approach to Feeding, and Integrated Listening Systems. In 2010, Dr. Porter completed a fellowship at the STAR Institute, where she was mentored closely by Drs. Lucy Jane Miller and Sarah Schoen.",
            "1": "Dr. Porter is an assistant professor in the Occupational Therapy Doctorate program at Western Oregon University in Salem, Oregon. She is the founder of Sensory KIDS, in Portland, OR."
          },
        },
        "cara": {
          "title": "Cara Sheekey",
          "bio": {
            "0": "Cara Sheekey, MOT is an occupational therapist with 20 years of clinical pediatric experience. She has worked in private practice and special education settings in Australia and the USA, and is the co-founder of KidsRISE in Melbourne Australia. Cara has completed Level 1 STAR Intensive Mentorship, Levels 1 & 2 SPD Proficiency Certification, and has a professional Level 2 certification in DIR/Floortime from Profectum Foundation.",
          },
        },
        "emma": {
          "title": "Emma Brady",
          "bio": {
            "0": "Emma Brady, MOccTherPrac is a pediatric occupational therapist with clinical experience in both Australia and Canada. She has practiced in a range of pediatric settings, including early childhood intervention, private clinics, and research centres. She currently runs her own private practice in Burnaby, British Columbia. Emma has completed Level 1 STAR Intensive Mentorship, Levels 1 & 2 SPD Proficiency Certification, ATTACh Level 1 Trauma and Attachment Certification, and Basic DIR/Floortime Certification.",
          },
        },
        "breanne": {
          "title": "Breanne Kearney",
          "bio": {
            "0": "Breanne Kearney, MRes, MS OTR/L is an occupational therapist and PhD candidate in the Department of Neuroscience at Western University in London, Ontario, Canada. Breanne completed Levels I and II of the Intensive Mentorship Program at the STAR center in Denver, CO while working under the supervision of Dr. Lisa Porter. Breanne practiced in pediatric occupational therapy in various settings, including schools, early intervention home-based, and private clinics before pursuing research. Her current research surrounds the cognitive neuroscience and neurobiology of sensory processing in clinical conditions such as autism and post-traumatic stress disorder.",
          },
        }
      },
      "contact": {
        "messageSent": "Thank you, your enquiry has been sent",
        "messageError": "Sorry enquiry failed to send, please email {{email}}"
      },
      "research": {
        "title": "Research and Presentations",
        "symposium2023": {
          "title": "STAR Symposium 2023",
          "type": "Poster",
          "desc": "Professional Reasoning in Play-Based OT: APilot Study of Professional Reasoning and Reflection on Observation: An Organizational Framework (PROOF)",
        },
        "otExchange2024": {
          "title": "OT Exchange 2024",
          "type": "Oral Presentation",
          "desc": "Development and Clinical Application of PROOF, an Emerging Professional Reasoning Tool for Play Based Paediatric OT",
        },
        "oregonOt2024": {
          "title": "Oregon State OT Conference 2024",
          "type": "Workshop Presentation",
          "desc": "Enhancing Professional Reasoning and Reflection in Pediatric Therapy: Organizing Observations to Support Neurodivergent Affirming Practices through Embodied Play",
        },
        "journal2024": {
          "title": "Journal Article (submitted for review) ",
          "type": "Download supplemental materials",
          "desc": "Professional Reasoning in Play-Based OT: A Pilot Study of Professional reasoning and Reflection on Observation (PROOF): An Organizational Framework",
        },
      },
      "resources": {
        "title": "Resources",
        "videoTitle": "Watch the following video for a brief introduction to PROOF:",
        "fileTitle": "Download the resources below to begin using PROOF:",
        "description": {
          "0": "Welcome to the PROOF Resource Page!",
          "1": "Here you will find all PROOF resources, designed to support the ongoing development of professional reasoning and reflection in play-based pediatric occupational therapy.",
          "2": "These resources are tailored for use by experienced occupational therapists who have training in at least one of the following approaches: Ayres Sensory Integration (ASI), DIR/Floortime, STAR Proficiency, or other sensory or relationship-based approaches. We believe that PROOF is applicable to a wider interprofessional audience, but may require additional training.",
          "3": "In addition to use for your own professional development, PROOF is used for mentoring and supervising others.",
          "4": "If you are interested in utilising the PROOF framework but feel that you need further training or explanation, please <1>contact the authors</1> to request a supervision session.",
          "5": "Please note that both this website and PROOF itself are part of an emerging process. We will continue to add training resources and welcome any suggestions you may have for further enhancements."
        },
        "introVideo": "PROOF Introduction",
        "manualButton": "Manual",
        "manualDescription": "Complete with detailed instructions for using PROOF effectively.",
        "reflectionButton": "Record and Reflection Sheet",
        "reflectionDescription": "A tool for documenting and reflecting on your observations.",
        "domainButton": "Domain Descriptors",
        "domainDescription": "Detailed descriptions of the developmental domains used in our observational framework.",
        "buttonText": "Download",
        "downloadError": "Failed to download resource please <1>reload page</1> and try again",
      },
      "400": {
        "title": "400 - Authentication Error",
        "text": "Sorry we could not log you in at this time, please try again. If this error persists please <1>contact us</1>",
      },
      "401": {
        "title": "401 - Permission Denied",
        "text": "You do not have permission to view this page, return to <1>home</1>",
      },
      "403": {
        "title": "You must login to access PROOF resources.",
        "signup": "Don’t have an account? <1>Register</1>",
      },
      "404": {
        "title": "404 - Oops! You seem to be lost.",
        "text": "The page you were looking for appears to have moved or does not exist",
        "linkText": "Return Home"
      }
    }
  },
  "en-US": {
    translation: {
      "proof": {
        "description": "_P_rofessional Reasoning and _R_eflection on _O_bservation: An _O_rganizational _F_ramework",
        "intro": {
          "0": "PROOF is an emerging clinical reasoning and reflection tool for organizing clinical observations across developmental domains to support the design of individualized play-based interventions.",
        },
      },
      "about": {
        "description": "PROOF is a structured observational framework that supports advanced professional reasoning and reflection for pediatric occupational therapists using a sensory-motor, play-based approach. It is designed to guide therapists in organizing qualitative data across the domains of praxis, postural, and social-emotional development, while considering each child's individual sensory processing differences and encouraging self-reflection on part of the therapist. Through structured observations and guided reasoning, therapists use PROOF to design individualized, occupation-based interventions centered around child-led play. An overarching goal of PROOF is to emphasize the therapeutic benefits of embodiment, both for the client and for the therapist in advancing their professional reasoning.",
      },
      "resources": {
        "title": "Resources",
        "description": {
          "4": "If you are interested in utilizing the PROOF framework but feel that you need further training or explanation, please <1>contact the authors</1> to request a supervision session.",
        },
      }
    },
  },
  "es-ES": {
    translation: {
      "proof": {
        "intro": {
          "0": "PROOF es una herramienta emergente de razonamiento y reflexión clínica para organizar observaciones clínicas en todos los dominios del desarrollo para respaldar el diseño de intervenciones individualizadas basadas en el juego.",
          "1": "PROOF proporciona una estructura para el razonamiento clínico y guía a los terapeutas a centrarse en intervenciones corporales conectadas que apoyan el sentido de agencia del niño a través de una ocupación significativa y experiencias encarnadas.",
          "2": "PROOF apoya la reflexión sobre la acción del médico, lo que conduce al proceso clínico más avanzado de reflexión en la acción.",
        },
      },
    }
  }
};

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
  resources,
  debug: false,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;