import React, {useState} from "react";
import PageLayout from "../components/PageLayout.jsx";
import SurveyPage from "../components/Survey.jsx";
import {contactForm} from "../forms/contact";
import {useApi} from "../services/ApiService";
import {useTranslation} from "react-i18next";

export default function Contact() {
  const {t} = useTranslation();
  const api = useApi();
  
  const [message, setMessage] = useState(null);

  const onSubmit = async (formValues) => {
    const response = await api.sendMessage(formValues);
    if (response.success) {
      setMessage(t("contact.messageSent"))
      return {clear: true};
    } else {
      setMessage(t("contact.messageError", {email: t('proof.enquiryEmail')}))
    }
  }

  return (
    <PageLayout title="Contact">
      <h1 className="mb-2 text-center">Contact</h1>
      <div className="flex flex-col items-center">
        <SurveyPage survey={contactForm} onSubmit={onSubmit} clearOnRefresh={true} />
        {message && <span className="mt-2 text-sm">{message}</span>}
      </div>
    </PageLayout>
  )
}