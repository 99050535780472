import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {useClickOutside} from "../services/AppContext.jsx";

const Label = ({text}) => {
  return (
    <label id="listbox-label" className="sr-only">{text}</label>
  )
}

const SelectButton = ({onClick, children}) => {
  return (
    <div onClick={onClick}>
      {children ? children : "Select an option"}
    </div>
  )
}

const SelectOptions = ({onOptionClick, options, selectedOption}) => {
  return (
    <ul
      className="absolute right-0 z-20 mt-2 min-w-40 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      tabIndex="-1" role="listbox" aria-labelledby="listbox-label">
      {options.map((option, index) => {
        const selected = selectedOption === option;
        return (
          <li key={index} onClick={() => onOptionClick(option)}
              className={clsx("flex items-center cursor-default select-none p-4 text-sm hover:bg-slate-100", selected ? "text-proof-blue" : "text-gray-900")}
              role="option" aria-selected={selected}>
            {option.icon
              ? (<div className="flex gap-2 items-center justify-center">
                <div>{option.icon}</div>
                <div>{option.name}</div>
              </div>)
              : option.name}
          </li>
        )
      })}
    </ul>
  )
}

const SelectBox = ({classNames, label, onChange, options, value, children}) => {
  const menuRef = useClickOutside(() => setIsOpen(false));
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSelectedOption(options.find((option) => option.value === value));
  }, [value, options]);

  const handleButtonClick = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <div ref={menuRef} className={clsx("relative", classNames)}>
      <Label text={label}/>
      <SelectButton onClick={handleButtonClick}>
        {children}
      </SelectButton>
      {isOpen && <SelectOptions options={options} selectedOption={selectedOption} onOptionClick={handleOptionClick}/>}
    </div>
  );
}

export default SelectBox;
