import React from 'react';
import {useTranslation} from 'react-i18next';
import Container from "./Container.jsx";
// import NewsletterSubscribe from "./NewsletterSubscribe";

const PageNavigation = () => {
  const {t} = useTranslation();
  const year = new Date().getFullYear();
  return (
    <footer>
        <h2 id="footer-heading" className="sr-only">Footer</h2>
        <div className="py-4 px-4 border-t border-gray-800">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-center">
            <small className="text-secondary">
              &copy; {year} <a href="/" className="hover:underline">{t('proof.title')}</a>&nbsp;
              All Rights Reserved&nbsp;
              <span className="hidden sm:block md:inline">({t('proof.authors')})</span>
            </small>
            {/*<NewsletterSubscribe/>*/}
          </div>
        </div>
    </footer>
  );
}
export default PageNavigation;
