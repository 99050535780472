import React, {useState} from "react";
import {useApi} from "../services/ApiService";
import {Trans, useTranslation} from "react-i18next";

import DownloadButton from "../components/DownloadButton.jsx";
import PageLayout from "../components/PageLayout.jsx";
import SvgIcon from "../components/SvgIcon.jsx";

export default function Resources() {
  const {t} = useTranslation();
  const [error, setError] = useState(false);
  const api = useApi();

  const fileDownload = async (fileName, version) => {
    try {
      setError(false);
      // get new signed s3 urls from backend
      const response = await api.resourcesGet();
      if (response.success) {
        const resources = response?.data?.resources ?? [];
        const resource = resources.find(r => r.name === fileName && r.version === version);
        if (resource) {
          const url = new URL(resource.url);

          // Create a link to trigger a file download
          const link = document.createElement('a');
          link.href = url.toString();
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          console.error("Resource not found:", fileName, version);
          setError(true);
        }
      }
    } catch (error) {
      console.error("Error fetching resources for download:", error);
      setError(true);
    }
  };

  return (
    <PageLayout title="Resources">
      <h1 className="mb-2">{t("resources.title")}</h1>
      <p className="text-secondary">{t('resources.description.0')}</p>
      <p className="text-secondary my-4">{t('resources.description.1')}</p>
      <p className="text-secondary my-4">{t('resources.videoTitle')}</p>
      <ul className="mt-4 divide-y divide-gray-100 rounded-md border border-gray-200 mx-auto">
        <li className="flex items-center justify-between py-4 pl-4 pr-5 leading-6">
          <div className="flex w-0 flex-1 items-center">
            <div className="ml-4 flex min-w-0 flex-1 flex-col gap-1 items-center">
              <span className="truncate font-medium">{t("resources.introVideo")}</span>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/q2YqqjcQvAE?si=8IR8nIIs9-QbJ6Qm" title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen></iframe>
            </div>
          </div>
        </li>
      </ul>
      <p className="text-secondary my-4">{t('resources.fileTitle')}</p>
      <ul className="mt-4 divide-y divide-gray-100 rounded-md border border-gray-200 mx-auto">
        <li className="flex items-center justify-between py-4 pl-4 pr-5 leading-6">
          <div className="flex w-0 flex-1 items-center">
            <SvgIcon type="attachment"/>
            <div className="ml-4 flex min-w-0 flex-1 flex-col gap-1">
              <span className="truncate font-medium">{t("resources.manualButton")}</span>
              <span className="truncate text-sm font-light">{t("resources.manualDescription")}</span>
            </div>
          </div>
          <div className="ml-4 flex-shrink-0 flex gap-4 div">
            <DownloadButton file="proofManual" version="en" onClick={fileDownload}>{t("English")}</DownloadButton>
            <DownloadButton file="proofManual" version="es-ES" onClick={fileDownload}>{t("Español")}</DownloadButton>
          </div>
        </li>
        <li className="flex items-center justify-between py-4 pl-4 pr-5 leading-6">
          <div className="flex w-0 flex-1 items-center">
            <SvgIcon type="attachment"/>
            <div className="ml-4 flex flex-col min-w-0 flex-1 gap-1">
              <span className="truncate font-medium">{t("resources.reflectionButton")}</span>
              <span className="truncate text-sm font-light">{t("resources.reflectionDescription")}</span>
            </div>
          </div>
          <div className="ml-4 flex-shrink-0 flex gap-4 div">
            <DownloadButton file="reflectionSheet" version="en" onClick={fileDownload}>{t("English")}</DownloadButton>
            <DownloadButton file="reflectionSheet" version="es-ES" onClick={fileDownload}>{t("Español")}</DownloadButton>
          </div>
        </li>
        <li className="flex items-center justify-between py-4 pl-4 pr-5 leading-6">
          <div className="flex w-0 flex-1 items-center">
            <SvgIcon type="attachment"/>
            <div className="ml-4 flex flex-col min-w-0 flex-1 gap-1">
              <span className="truncate font-medium">{t("resources.domainButton")}</span>
              <span className="truncate text-sm font-light">{t("resources.domainDescription")}</span>
            </div>
          </div>
          <div className="ml-4 flex-shrink-0 flex gap-4 div">
            <DownloadButton file="domainDescriptors" version="en" onClick={fileDownload}>{t("English")}</DownloadButton>
            <DownloadButton file="domainDescriptors" version="es-ES" onClick={fileDownload}>{t("Español")}</DownloadButton>
          </div>
        </li>
      </ul>
      <p className="text-secondary my-4">{t('resources.description.2')}</p>
      <p className="text-secondary my-4">{t('resources.description.3')}</p>
      <p className="text-secondary my-4">
        <Trans i18nKey="resources.description.4">
          If you are interested in utilising the PROOF framework but feel that you need further training or explanation, please <a
          className="font-medium underline hover:opacity-50" href="/contact">contact the authors</a> to request a supervision
          session.
        </Trans>
      </p>
      <p className="text-secondary my-4">{t('resources.description.5')}</p>
      {error && (
        <div className="mt-2 text-sm text-center text-red-800">
          <Trans i18nKey="resources.downloadError">
            An error occurred please <a className="font-medium underline hover:opacity-50" href="/resources">reload the
            page</a> and try again
          </Trans>
        </div>)}
    </PageLayout>
  )
}