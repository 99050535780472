import {useAuth} from "../services/auth/CognitoAuthContext";
import {Navigate, useNavigate} from "react-router-dom";
import {useApi} from "../services/ApiService";
import React from "react";

import {termsAndConditions} from "../forms/survey";
import PageLayout from "../components/PageLayout.jsx";
import SurveyPage from "../components/Survey.jsx";

export default function Survey() {
  const navigate = useNavigate();
  const {authenticated, termsAccepted, refresh} = useAuth();
  const api = useApi();

  const onSubmit = async (surveyData) => {
      const response = await api.sendAction(surveyData);
      if (response.success) {
        await refresh();
        navigate("/resources");
        return {clear: true};
      }
      // todo: handle signup errors
  };

  // Determine the routing based on user's status
  if (authenticated === false || termsAccepted === true) {
    return <Navigate to="/"/>;
  }

  return (
    <PageLayout title="Terms">
      <section aria-labelledby="section-heading rounded-lg">
        <div className="relative min-h-80 rounded-lg">
          <div aria-hidden="true" className="absolute inset-0 bg-opacity-50 rounded-lg"></div>
          <div className="relative flex max-w-full flex-col items-center text-center">
            <SurveyPage survey={termsAndConditions} onSubmit={onSubmit} />
          </div>
        </div>
      </section>
    </PageLayout>
  )
}

