import React from "react";
import PageLayout from "../components/PageLayout.jsx";
import FormatText from "../components/FormatText.jsx";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Home() {
  const {t} = useTranslation();
  
  return (
    <PageLayout title="Home" isFullWidth={true}>
      <section aria-labelledby="section-heading">
        <div className="relative bg-gray-800 px-6 sm:px-12 transition-[padding] py-4 sm:py-10 md:py-15 lg:py-20 lg:px-16">
          {/*<CoverImage imageUrl="https://images.unsplash.com/photo-1606092195730-5d7b9af1efc5?q=80"/>*/}
          <div aria-hidden="true" className="absolute inset-0 bg-proof-blue"></div>
          <div aria-hidden="true" className="absolute inset-0 bg-gray-900 bg-opacity-80"></div>
          <div className="relative mx-auto flex max-w-full flex-col items-center text-center">
            {/*<img className="object-cover h-24 mb-10" src="/images/proof-logo.png" alt={t('proof.title')}/>*/}
            <h2 id="section-heading" className="font-bold text-2xl tracking-tight text-white">
              <FormatText textKey="proof.description" colours={["text-proof-green","text-proof-orange","text-proof-blue","text-proof-yellow","text-proof-purple"]} classNames="" type="caps"/>
            </h2>
            <p className="mt-3 font-light text-xl text-white">{t('proof.intro.0')}</p>
            <p className="mt-3 font-light text-xl text-white">{t('proof.intro.1')}</p>
            <p className="mt-3 font-light text-xl text-white">{t('proof.intro.2')}</p>
            <Link to="/resources" className="mt-8 button button-white">{t('proof.resourcesButton')}</Link>
          </div>
        </div>
      </section>
    </PageLayout>
  )
}