import React from "react";

import PageLayout from "../components/PageLayout.jsx";

export default function Loading() {
  return (
    <PageLayout>
      <section aria-labelledby="section-heading rounded-lg">
        <div className="relative px-6 py-4 sm:px-12 lg:px-16 rounded-lg h-full animate-pulse">
          <div className="relative mx-auto flex max-w-full flex-col items-center text-center">
          </div>
        </div>
      </section>
    </PageLayout>
  )
}